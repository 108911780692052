import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

import { CHAIN_ID } from '@/constants/settings';

import MintLayout from '@/sections/mint/MintLayout';
import MintCard from '@/sections/mint/MintCard';
import BuyOnOpensea from '@/sections/mint/BuyOnOpensea';

import useBoundedTokens from '@/hooks/useBoundedTokens';
import useSafeSoulContract from '@/hooks/useSafeSoulContract';

import goToErrorPage from '@/helpers/goToErrorPage';

const MintSuccess = (props) => {
  const { chain } = useNetwork()
  const { switchNetwork, error } = useSwitchNetwork();

  const { address } = useAccount();
  const [mintedTokensInfo, setMintedTokensInfo] = useState(0);
  const [transactionHash, setTransactionHash] = useState('');
  const [transactionMintCount, setTransactionMintCount] = useState('');

  const ssContract = useSafeSoulContract();

  const countBoundTokens = useBoundedTokens(address);

  useEffect(() => {
    if (chain?.id !== CHAIN_ID && switchNetwork) {
      switchNetwork(CHAIN_ID);
    }
  }, [chain, switchNetwork]);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const hash = urlParams.get('hash');
    const count = urlParams.get('count');

    if (!hash || !count) {
      goToErrorPage();
    };

    setTransactionHash(hash);
    setTransactionMintCount(count);
  }, []);


  const handleOwnTokens = useCallback(async () => {
    if (!address) return;

    try {
      const result = await ssContract.balanceOf(address, 1);

      setMintedTokensInfo(Number(result));
    } catch (e) {
      console.error(e);
    }
  }, [address]);

  useEffect(() => {
    handleOwnTokens && handleOwnTokens();
  }, [handleOwnTokens]);

  const link = `https://${CHAIN_ID === 5 ? 'goerli.' : ''}etherscan.io/tx/${transactionHash}`

  return (
    <MintLayout>
      <div className="min-h-screen">
        <MintCard title={"Congratulations"}>
          <div className='max-w-sm mx-auto'>
            <div className="font-medium text-center">
              <p>You have successfully minted {transactionMintCount} SafeSoul tokens.</p>
              <p>Click <a href={link} target="_blank" className='underline transition hover:opacity-80'>here</a> to view transaction</p>
            </div>

            <div className="mt-8">
              <ul className="text-black/70 font-medium space-y-4 pl-5">
                <li className='before:content-["—"] before:mr-1 before:-ml-5'>Activate your token and get a bound token to join Patrol</li>
                <li className='before:content-["—"] before:mr-1 before:-ml-5'>Make web3 safe again</li>
              </ul>
            </div>
          </div>
        </MintCard>

        {countBoundTokens === 0 ?
          <>
            {mintedTokensInfo > 0 &&
              <div className="mt-2">
                <Link to='/mint-activate' className='flex items-center justify-center w-full rounded-2xl py-5 px-10 font-bold transition bg-[#C2E900] hover:bg-[#C2E900]/80'>Activate</Link>
              </div>
            }
            <div className='mt-2 text-center text-[#888888] text-xs font-medium'>Bound token is non-transferable token that is needed<br />to provide control over "one wallet one vote" principle.
            </div>
          </>
          :
          <>
            <div className="mt-2">
              <MintCard>
                <div className='max-w-sm mx-auto'>
                  <div className="font-medium text-center">
                    You already have a SafeSoul Bound token
                  </div>
                </div>
              </MintCard>
            </div>

            <div className='mt-2'>
              <BuyOnOpensea rouded="rounded-2xl" />
            </div>
          </>
        }
      </div>
    </MintLayout >
  );
};

export default MintSuccess;